import React from "react"
import { Layout, Hero, SEO } from "../components"
import styled from "styled-components"
import { Button } from "@material-ui/core"
import { IoMdArrowRoundForward } from "react-icons/io"
import img from "../assets/images/profile.jpg"

export default function AboutPage() {
  return (
    <Layout>
      <SEO title="About" />
      <Hero>About</Hero>
      <Contents>
        <div class="summary">
          <p>
            Hi, I’m Kaede Nakashima. I'm a front end developer who mainly focus
            to use <b>React</b>. I’ve been working in this field for over 4
            years in my career. Also, I love creating user friendly web sites
            ☺︎. I develop web application <b>simpler</b> and <b>easier</b> as
            possible.
          </p>
          <img src={img} alt="profile image" />
        </div>
        <h2>
          Web Stack Detail
          <br />
          <span>Skills & tools I use for my React Project Major Skills</span>
        </h2>
        <div class="container">
          <div class="grid">
            <h3>Major Skills</h3>
            <ul>
              <li>
                <div>React</div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    data-progress="65"
                    style={{ width: "50%" }}
                  >
                    <span>65%</span>
                  </div>
                </div>
              </li>
              <li>
                <div>Git</div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    data-progress="65"
                    style={{ width: "50%" }}
                  >
                    <span>65%</span>
                  </div>
                </div>
              </li>
              <li>
                <div>JavaScript</div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    data-progress="65"
                    style={{ width: "35%" }}
                  >
                    <span>65%</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-l">
            <h3>Other Skills for React</h3>
            <p>
              JS open resource libraries: Gatsby, GraphQL, Apollo, Redux, MDX |
              User Interface: Material UI | API Database: MongooDB, Firebase,
              contentful, Airtable, Strapi | API Search Engine: Algoria | API
              Testing: Jest, Postman | CSS: tailwindcss, styled components,
              Rebass, BEM, SCSS/SASS, Bootstrap | PaymentPlatform: Stripe,
              Shopify | Adobe Softwares: XD, Premiere Pro, Illustrator,
              Photoshop | react animation: react-spring, React move, React
              Transition Group
            </p>
          </div>
          <h3>Enviroment</h3>
          <div class="env-details">
            • MacBook Pro 15-inch, 2016
            <br />• VS code Extensions:
            <ul>
              <li>ES7</li>
              <li>React/Redux/GraphQL/React-Native snippets</li>
              <li>GraphQL for VSCode</li>
              <li>Indent one space</li>
              <li>MDX</li>
              <li>Prettier</li>
              <li>vscode-styled-components</li>
              <li>ESLint</li>
            </ul>
          </div>
        </div>

        <div className="btn-container">
          <a
            href="https://github.com/kaedenakashima?tab=repositories"
            target="_blank"
          >
            <Button className="btn" variant="contained" color="primary">
              <span className="btn-txt">
                <i>See my code @Github</i>
                <IoMdArrowRoundForward style={{ verticalAlign: "middle" }} />
              </span>
            </Button>
          </a>
        </div>
        <div className="stack-container"></div>
      </Contents>
    </Layout>
  )
}

const Contents = styled.div`
  font-family: "Courier New", Courier, monospace;
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  h2 {
    font-weight: 400;
    font-size: 30px;
    color: rgb(0 0 0 / 77%);
    margin: 60px 0 1em;
  }
  h2 span {
    font-weight: 400;
    font-size: 14px;
    color: rgb(0 0 0 / 77%);
  }
  h3 {
    font-weight: 400;
    font-size: 20px;
    color: rgb(0 0 0 / 77%);
  }
  p {
    line-height: 2;
    margin-bottom: 3rem;
    font-size: 20px;
    font-family: var(--ff-primary);
    font-weight: 500;
    text-transform: initial;
    color: #383838;
    letter-spacing: 0;
  }
  .summary {
    display: flex;
  }
  .summary p {
    flex: auto;
  }
  .summary img {
    flex: auto;
    width: auto;
    height: 140px;
    margin-left: 50px;
  }

  .progress {
    background-color: #e9e9e9;
    border-radius: 0;
    height: 5px;
    overflow: visible;
    margin-bottom: 15px;
  }
  .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    background-color: #303030;
    min-width: 32px;
    width: 60%;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;
  }
  .col-l {
    padding-left: 0;
    margin: 3em 0 0;
  }
  .col-l p {
    line-height: 1.5em;
    font-size: 12px;
    font-family: var(--ff-primary);
    font-weight: 500;
    text-transform: initial;
    color: #383838;
    -webkit-letter-spacing: 0;
    text-align: justify;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
  }
  .env-details,
  .env-details ul {
    padding-left: 20px;
    font-family: "Baskervville", sans-serif;
  }
  .grid li > div {
    font-family: "Baskervville", sans-serif;
  }
  .btn-container {
    text-align: center;
    margin-bottom: 3em;
  }
  .btn {
    background-color: #fff;
    border-radius: 26px;
    margin-top: 20px;
    padding: 5px 30px;
    border: 0.5px solid #383838;
    box-shadow: none;
    font-family: var(--ff-primary);
  }
  .btn:hover {
    background-color: #9e9e9e38;
    box-shadow: none;
  }
  .btn-txt {
    font-weight: 500;
    font-size: 16px;
    text-transform: initial;
    color: #383838;
  }
  .icon {
    vertical-align: middle;
    padding-left: 8px;
    font-size: 20px;
  }
  @media screen and (min-width: 737px) {
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
    }
    .col-l {
      padding-left: 20px;
      margin: 0;
    }
    h2 span {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 992px) {
    width: 772px;
    margin: 2.3rem auto 0;
  }
`
